<template>
	<div class="hx-order-three">
		<!-- 信息 -->
		<div align="left" class="left"  >
				<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px" style="vertical-align: middle;" >
				<div>
					<h3 data-i18n="[html]forms.order.patient_information">留言</h3>
					<p data-i18n="forms.order.patient_information_content">如有特殊信息请在此处留言</p>
				</div>
		</div>
		<div class="right" align="left" style="width: 45%;">
			<div id="zlcon" style="margin-top: 0.625rem;z-index: 0;"></div>
			<div align="right" style="margin-top: 0.625rem;">
				<el-button class="back" type="primary" @click="back">返回</el-button>
				<el-button class="nextStep" type="primary" @click="nextStepOrder">下一步</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import E from 'wangeditor';
	
	export default{
		data:function(){
			return{
				ruleForm:{
					oid:'',
					liuyan:''
				},
				editor:''
			}
		},
		methods:{
			back(){
				this.$store.commit("setHxStepIndex",7);
				this.$router.push("/hxmain/hxorder/hxorderseven");
			},
			nextStepOrder(){
				let ins=this.editor.txt.text();
				this.ruleForm.liuyan=ins;
				this.$axios.post("/client/order/addYsmLiuyan",JSON.stringify(this.ruleForm),{
					headers:{
						"Content-type":"application/json"
					}
				}).then(res=>{
					if(res.data.code=200){
						// this.$alert("留言成功","提示");
					}
					this.$store.commit("setHxStepIndex",9);
					this.$router.push("/hxmain/hxorder/hxordernine");
				}).catch(err=>{
					this.$alert("留言失败","提示");
				})
			},
			
		},
		mounted:function(){
			this.editor = new E('#zlcon')
			this.editor.create();
			this.$store.commit("setYsmStepIndex",8);
			//设置返回路径
			this.$store.commit("setBackLocation","/hxmain/hxorder/hxordereight");
		},
		created:function(){
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb die");
			/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			 document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3"); */
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
			if(oid!="undefined"&&oid!=''&&oid!=null&&oid!=undefined){
					this.ruleForm.oid=oid;
					//根据oid查询订单信息
					this.$axios.get("/client/order/getOrderInfo",{
						params:{
							oid:this.ruleForm.oid
						}
					}).then(res=>{
						let orderInfo=res.data.data;
						this.editor.txt.text(orderInfo.plan.liuyan)
					}).catch(err=>{
						this.$alert("系统出现异常,请联系管理员");
					});
			}else{
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/hxmain/hxorder/hxorderone");
					}
				});
				
			}
		}
	}
</script>

<style scoped="scoped">
	.hx-order-three{
		width: 80%;
		display: flex;
		margin: 0px auto;
		
		margin-top: 3.125rem;
		/* padding-bottom: 5.125rem; */
		padding-bottom: 10px;
		justify-content: space-around;
	}
	h3{
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #0D8EA4;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.left{
		display: flex;
		width: 25rem;
		position: relative;
		justify-content: flex-start;
	}
	#zlcon{
		width: 560px;
	}
	#zlcon div{
		z-index: 0 !important;
	}
	/* 右边 */
	.right{
		/* position: relative;
		left: 9.55rem; */
		width: 560px !important;
	}
	/* 按钮 */
	.nextStep{
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}
	.back{
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #0D8EA4 !important;
		color: #0D8EA4 !important;
	}
</style>
